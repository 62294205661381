import React from 'react'

const FeatureCard = ({
    data,
    index
}) => {
    return (
        <div 
            className={`feature_card col-12 col-md-6 col-xl-4 wow animate__fadeIn`}
            data-wow-duration={`${(index * 1) + 1}s`}
        >
            <div className='feature_card_container'>
                <div className='feature_card_icon_container'>
                    <img src={data.cardIcon} alt='card-icon'/>
                </div>
                <div className='feature_card_info_container'>
                    <span className='feature_card_info_container_title'>{data.cardTitle}</span>
                    <span className='feature_card_info_container_description'>{data.cardDescription}</span>
                </div>
            </div>
        </div>
    )
}

export default FeatureCard
