import React, { useState } from 'react'

// Router
import {navigate} from 'gatsby'

//Redux
import {useSelector} from 'react-redux'

// Localized Data
import communicationBarData from "../../../localized/communicationBarLocalized.json"
import buttonsData from "../../../localized/buttonsLocalized.json"

// Tools
import LoadingSpinner from "../../Tools/LoadingSpinner/LoadingSpinner"

/*  API: Type (getDemo, joinWaitlist, contactUs) determines what the submit button says */

/* Pricing sayfasi uzerinden contact us istegi gonderildiginde disaridan alinan
subjectType degiskeni araciligi ile subject type, navigatein icinde sayfaya gonderilecektir. */

const CommunicationBar = ({
    id,
    inputValue,
    changeInputValue,
    navigateLink,
    type,
    white,
    subjectType,
    blankPage
}) => {
    // Regices
    const emailRegex = /^\w+([.-]\w+)*@\w+([.-]\w+)*(\.\w{2,})/;

    // Current Language
    const currentLanguage = useSelector(state => state.changeLanguageReducer)

    // Invalid Email State
    const [invalidEmail, setInvalidEmail] = useState(false);

    const [loading, setLoading] = useState(false);

    const onSubmitFunction = () => {
        setLoading(true);
        if (emailRegex.test(inputValue)) {
            setLoading(true);
            if (blankPage) {
                window.open(navigateLink, '_blank');
            } else {
                navigate(navigateLink, {
                    state : {
                    requestEmail : inputValue,
                    }
                })
            }
            changeInputValue('');
            setLoading(false);
        } else {
            setLoading(false);
            setInvalidEmail(true);
        }
    }
    
    return (
        <div 
            id={id}
            className={`communication_bar ${invalidEmail ? "communication_bar_invalid" : ""} ${white ? "communication_bar_white" : ""}`}
        >
            <input
                className={`communication_bar_input ${invalidEmail ? "communication_bar_input_invalid" : ""}`}
                type="email"
                placeholder={communicationBarData.enterWorkEmail?.[currentLanguage]}
                value={inputValue}
                onChange={(e) => {
                        setInvalidEmail(false);
                        changeInputValue(e.target.value);
                    }
                }
                onKeyPress={(e) => {
                    if (e.code === "Enter") {
                        onSubmitFunction();
                    }
                }}
            />
            {invalidEmail 
                ? (
                    <span
                        className="communication_bar_invalid_warning"
                    >
                        {communicationBarData.invalidEmail?.[currentLanguage]}
                    </span>
                )
            
            : (
                <button
                    className={`website_btn discover_btn ${loading ? "bg-black" : ""}`}
                    onClick={onSubmitFunction}
                >   
                       { 
                        loading ?
                        <LoadingSpinner  
                            otherClassName="h-100"
                            spinnerBorderClass="spinner_border_2"
                            whiteSpinner  
                        />  :
                           buttonsData?.[type]?.[currentLanguage]
                        }
                     
                </button>
                )
            }
        </div>
    )
}

export default CommunicationBar
