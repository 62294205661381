import React from "react";
import "./LoadingSpinner.css";

// Localized Data
import loadingSpinnerData from "../../../localized/approvalsDetailLocalized.json"

// Illustrations
import magicIllustration from "../../../assets/illustration/magic-illustration.svg"
import uploadDocumentIllustration from "../../../assets/illustration/uploading-document-illustration.svg"

const LoadingSpinner = ({
  uploadPhoto,
  uploadType, 
  whiteSpinner,
  otherClassName,
  spinnerBorderClass
}) => {

  //Current language data
  const currentLanguage = localStorage.getItem('language')

  return (
    <div className={`loading_spinner_area ${uploadPhoto ? "upload_spinner" : ""} ${otherClassName ? otherClassName : ""}`} >
      {uploadPhoto ?
        uploadType === "create_expense" ?
          <div className="upload_photo_spinner_body">
            <span>{loadingSpinnerData?.doingOurAI?.[currentLanguage]}</span>
            <img src={magicIllustration} alt="AI magic illustration" />
          </div>
        : 
          <div className="upload_photo_spinner_body">
            <span>{loadingSpinnerData?.uploadingDocument?.[currentLanguage]}</span>
            <img src={uploadDocumentIllustration} alt="Uploading Document Illustration" />
          </div>
      
      : ""}
      <div className={`spinner-border ${whiteSpinner ? "white_spinner" : ""} ${spinnerBorderClass ? spinnerBorderClass : ""}`} role="status"></div>
    </div>
  );
};

export default LoadingSpinner;
