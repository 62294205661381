import React from 'react'

//Components
import FeatureCard from './FeatureCard/FeatureCard'

const FeatureContainer = ({data}) => {
    return (
        <div className="feature_container row w-100">
            {data.map((data, i) => (
            <FeatureCard 
                data={data}
                key={i}
                index={i}
            />
            ))}
      </div>
    )
}

export default FeatureContainer