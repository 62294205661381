import React, { useState } from "react"

//redux
import { useSelector } from "react-redux"

//Components
import Section from "../../components/Tools/Section/Section"
import FeatureContainer from "../../components/Tools/FeatureContainer/FeatureContainer"
import CommunicationBar from "../../components/Tools/CommunicationBar/CommunicationBar"

//Icons
import HomeSS from "../../assets/company/home.svg"

//Icons for second section datas
// import creditCardIcon from "../../assets/company/credit-card.svg";
import transferIcon from "../../assets/company/transfer-icon.svg"
import exchangeIcon from "../../assets/company/exchange-icon.svg"
import clockIcon from "../../assets/company/clock-icon.svg"

//Localized
import communicationBarData from "../../localized/communicationBarLocalized.json"
import businessAccountData from "../../localized/businessAccountLocalized.json"
import Layout from "../../components/Layout"

const BusinessAccount = () => {
  const currentLanguage = useSelector(state => state.changeLanguageReducer)

  const [signUpEmail, setSignUpEmail] = useState("")

  const businessAccountFeaturesData = [
    {
      cardIcon: clockIcon,
      cardTitle: businessAccountData.getInstantAccount?.[currentLanguage],
      cardDescription: businessAccountData.noMoreBranches?.[currentLanguage],
    },
    {
      cardIcon: transferIcon,
      cardTitle: businessAccountData.cheapEasyTransfers?.[currentLanguage],
      cardDescription: businessAccountData.withoutHeftyFees?.[currentLanguage],
    },
    {
      cardIcon: exchangeIcon,
      cardTitle: businessAccountData.getMarketRates?.[currentLanguage],
      cardDescription: businessAccountData.convertSpend?.[currentLanguage],
    },
  ]

  // useEffect(() => {
  //   setCurrentLanguage(localStorage.getItem('language'))
  // },[currentLanguage])

  const businessAccountFirstSection = (
    <div id="business-account-first-section" className="media_section">
      <div
        className="section_paragraph"
        id="business-account-first-section-paragraph"
      >
        <span
          data-wow-duration="2s"
          className="wow animate__fadeInUp section_paragraph_top white_text"
          id="third-section-title"
        >
          {businessAccountData.withoutLimits?.[currentLanguage]}
        </span>
        <span
          className="wow animate__fadeInLeft section_paragraph_middle white_text"
          data-wow-duration="1s"
          id="third-section-subtitle"
        >
          {businessAccountData.manageYourResources?.[currentLanguage]}
        </span>
        <div
          className="wow animate__fadeInUp section_paragraph_bottom"
          data-wow-duration="1.5s"
        >
          <span className="white_text">
            {businessAccountData.businessAccountPromo?.[currentLanguage]}
          </span>
        </div>
      </div>
      <div
        className="wow animate__fadeIn media_section_content"
        data-wow-duration="2s"
      >
        <img
          src={HomeSS}
          alt="Home mockup"
          className="media_section_content_mobile beige_media_shadow"
        />
      </div>
    </div>
  )

  const businessAccountThirdSection = (
    <div
      className="communication_bar_container wow animate__fadeIn"
      data-wow-duration="2s"
      id="business-account-third-section"
    >
      <div className="communication_bar_header">
        {`${communicationBarData.beTheFirst?.[currentLanguage]} !`}
      </div>
      <CommunicationBar
        id="business-account-signup-bar"
        inputValue={signUpEmail}
        changeInputValue={setSignUpEmail}
        navigateLink={`/waitlist/?lang=${currentLanguage}`}
        type="joinWaitlist"
      />
    </div>
  )

  return (
    <Layout>
      <div id="business-account">
        <Section
          backgroundColor="black"
          sectionContent={businessAccountFirstSection}
          sectionId="business-account-first-section-wrapper"
        />
        <Section
          backgroundColor="white"
          sectionContent={
            <FeatureContainer data={businessAccountFeaturesData} />
          }
          otherClassName="px-5"
        />
        <Section
          backgroundColor="white"
          sectionContent={businessAccountThirdSection}
          sectionId="business-account-third-section-wrapper"
          otherClassName="pt-0"
        />
      </div>
    </Layout>
  )
}

export default BusinessAccount
