import React from "react";

const Section = ({
  backgroundColor,
  sectionContent,
  topSection,
  otherClassName,
  sectionId,
}) => {
  // Background Color API: ["gray", "yellow", "black", "white"]
  // Top Section API : [true, false]- Top will have its top positioning to be the height of the navbar - in this case, 8 rem.
  return (
    <section
      id={sectionId}
      className={`section
      ${topSection ? "top_section" : ""} 
      ${backgroundColor}_section ${otherClassName || ""} 
      ${backgroundColor === "black" ? "white_paragraph_text" : "black_paragraph_text"}`}
    >
      {sectionContent}
    </section>
  );
};

export default Section;
